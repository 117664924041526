import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Data by state and offshore region",
  "layout": "content",
  "includeToc": true,
  "permalink": "/data-by-state-offshore-region/"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DatabyStateButtons = makeShortcode("DatabyStateButtons");
const DatabyOffshoreRegionButtons = makeShortcode("DatabyOffshoreRegionButtons");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "Data-by-state-and-offshore-region",
      "style": {
        "position": "relative"
      }
    }}>{`Data by state and offshore region`}</h1>
    <p>{`Select a state or offshore region to learn more about energy and minerals produced in that location. Each page includes direct links to `}<a parentName="p" {...{
        "href": "https://revenuedata.doi.gov/explore/"
      }}>{`explore data`}</a>{` and `}<a parentName="p" {...{
        "href": "https://revenuedata.doi.gov/query-data"
      }}>{`query data`}</a>{`, with those tools filtered for the selected location.`}</p>
    <h2 {...{
      "id": "Data-by-state",
      "style": {
        "position": "relative"
      }
    }}>{`Data by state`}</h2>
    <p>{`This list only includes states for which ONRR has data.`}</p>
    <DatabyStateButtons mdxType="DatabyStateButtons" />
    <h2 {...{
      "id": "Data-by-offshore-region",
      "style": {
        "position": "relative"
      }
    }}>{`Data by offshore region`}</h2>
    <DatabyOffshoreRegionButtons mdxType="DatabyOffshoreRegionButtons" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      